// In reducers.js
import { combineReducers } from "redux";
import authSlice from "./authSlice";
// Import your reducers here

const rootReducer = combineReducers({
  // Your reducers
  auth: authSlice,
});

export default rootReducer;
