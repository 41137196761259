import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Email from "../assets/email.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import BackgroundAuth from "../assets/bgauth.svg";
import { useDispatch, useSelector } from "react-redux";
import { contactUsAsync, resetContactUs } from "../redux/authSlice";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { loadingContactUs, contactUsData, errorContactUs } = useSelector(
    (state) => state.auth
  );

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      subject: Yup.string().required("Subject is required"),
      message: Yup.string().required("Message is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      dispatch(contactUsAsync(values));
    },
  });

  useEffect(() => {
    if (contactUsData) {
      toast.success("Submitted successfully!");
      formik.resetForm(); // Clear form fields
      dispatch(resetContactUs());
    } else if (errorContactUs) {
      toast.error(errorContactUs || "Failed to submit. Please try again.");
      dispatch(resetContactUs());
    }
  }, [contactUsData, errorContactUs, dispatch]);

  return (
    <div
      className="flex flex-col md:flex-row items-center justify-between p-4 md:p-12"
      style={{
        backgroundImage: `url(${BackgroundAuth})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* Contact Details */}
      <div className="text-center md:text-left w-full md:w-1/2 p-4">
        <h1 className="text-2xl md:text-3xl font-bold mb-6 text-gray-900 leading-tight md:leading-snug">
          Contact Us
        </h1>

        <div className="flex items-center gap-3 mb-4 justify-center md:justify-start">
          <img src={Email} className="w-6" alt="Email" />
          <a
            href="mailto:help@mtmnexus.com"
            className="text-lg text-gray-700 hover:text-blue-500"
          >
            help@mtmnexus.com
          </a>
        </div>

        <div className="flex items-center gap-4 justify-center md:justify-start">
          <a
            href="https://www.linkedin.com/company/mtm-nexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor"
          >
            <FontAwesomeIcon
              icon={faLinkedin}
              size="2x"
              className="text-blue-700"
            />
          </a>
          {/* <a
            href="https://www.instagram.com/mtmnexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="cursor"
          >
            <FontAwesomeIcon
              icon={faInstagram}
              size="2x"
              className="text-pink-500"
            />
          </a> */}
        </div>
      </div>

      {/* Contact Form */}
      <div className="w-full md:w-1/2 max-w-lg bg-white rounded-lg shadow-md p-6">
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-medium">
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className="w-full p-2 border border-gray-300 rounded"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500 text-sm">{formik.errors.name}</div>
            ) : null}
          </div>

          <div>
            <label htmlFor="email" className="block text-gray-700 font-medium">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              className="w-full p-2 border border-gray-300 rounded"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red-500 text-sm">{formik.errors.email}</div>
            ) : null}
          </div>

          <div>
            <label
              htmlFor="subject"
              className="block text-gray-700 font-medium"
            >
              Subject
            </label>
            <input
              id="subject"
              name="subject"
              type="text"
              className="w-full p-2 border border-gray-300 rounded"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.subject}
            />
            {formik.touched.subject && formik.errors.subject ? (
              <div className="text-red-500 text-sm">
                {formik.errors.subject}
              </div>
            ) : null}
          </div>

          <div>
            <label
              htmlFor="message"
              className="block text-gray-700 font-medium"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows="4"
              className="w-full p-2 border border-gray-300 rounded"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
            ></textarea>
            {formik.touched.message && formik.errors.message ? (
              <div className="text-red-500 text-sm">
                {formik.errors.message}
              </div>
            ) : null}
          </div>

          <button
            type="submit"
            className={`w-full px-4 py-2 rounded ${
              !formik.isValid || !formik.dirty || loadingContactUs
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            }`}
            disabled={!formik.isValid || !formik.dirty || loadingContactUs}
          >
            {loadingContactUs ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Send Message"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
