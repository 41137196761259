import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faSignOutAlt,
  faUser,
  faClock,
  faCaretDown,
  faCaretUp,
  faBa,
  faBars,
  faClose,
} from "@fortawesome/free-solid-svg-icons"; // Import faSignOutAlt and faUser
import HomeBackground from "../assets/homeBg.png";
import Logo from "../assets/mtmNexusLogo.png";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFifthTextAsync,
  fetchProfileAsync,
  resetAuthComponent,
} from "../redux/authSlice";
import { logoutAsync } from "../redux/authSlice";
import UserIcon from "./UserIcon";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showProductModal, setShowProductModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showProductSubmenu, setShowProductSubmenu] = useState(false);
  const [showDesktopProductSubmenu, setShowDesktopProductSubmenu] =
    useState(false);
  const { profileData, fifthTextData } = useSelector((state) => state.auth);
  const toggleProductModal = () => {
    setShowProductModal(!showProductModal);
  };

  const isPlanExpired =
    profileData?.subscription &&
    new Date() > new Date(profileData?.subscription?.endDate);

  const menuItems = [
    {
      label: " AI-Powered Medication Therapy Management (available now)",
      status: "available",
    },
    {
      label:
        "Licensed and Registered Pharmacist Review Oversight (available now)",
      status: "available",
    },
    {
      label: "Adherence Monitoring and Risk Scoring (coming soon)",
      status: "coming soon",
    },
    { label: "Cost Optimization (coming soon)", status: "coming soon" },
    {
      label: "Integrated Workflow Automation (coming soon)",
      status: "coming soon",
    },
    {
      label: "Comprehensive Analytics Dashboard (coming soon)",
      status: "coming soon",
    },
    { label: "Scalable Integrations (coming soon)", status: "coming soon" },
  ];

  useEffect(() => {
    // Check if the user is logged in on component mount
    setIsLoggedIn(!!localStorage.getItem("token"));
    dispatch(resetAuthComponent());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userID")) {
      dispatch(fetchProfileAsync({ userId: localStorage.getItem("userID") }));
    }
  }, [localStorage.getItem("userID")]);

  const handleClick = (type) => {
    if (isLoggedIn) {
      navigate("/AnalysisReport");
    } else {
      localStorage.setItem("SignFrom", type);
      navigate("/signin");
    }
  };

  const handleLogout = async () => {
    // try {
    //   const accessToken = localStorage.getItem("token");
    //   if (!accessToken) {
    //     throw new Error("Token is null");
    //   }

    dispatch(logoutAsync())
      .unwrap()
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("userID");
        localStorage.removeItem("email");
        toast.success("User signed out successfully");
        setIsLoggedIn(false); // Update state after logout
        navigate("/");
      })
      .catch((e) => toast.error("Cannot logout"));

    // if (response.error) {
    //   throw new Error(response.error.message || "Logout failed");
    // }

    //   localStorage.removeItem("token");
    //   localStorage.removeItem("userID");
    //   localStorage.removeItem("email");
    //   toast.success("User signed out successfully");
    //   setIsLoggedIn(false); // Update state after logout
    //   navigate("/");
    // } catch (error) {
    //   console.error("Failed to log out", error);
    //   toast.error("Logout failed. Please try again.");
    // }
  };
  useEffect(() => {
    dispatch(fetchFifthTextAsync());
  }, []);

  return (
    <div className="bg-gray-50">
      {/* Header */}
      <header
        className="flex justify-between md:flex-start items-center px-4 py-4 bg-white shadow-md relative"
        style={{ boxShadow: "0 2px 2px #00000040" }}
      >
        {/* Logo Section */}
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="MTM Nexus Logo"
            className="h-12 md:h-16 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>
        {/* Burger Icon for Mobile */}
        <button
          className="text-gray-900 md:hidden"
          onClick={() => setShowMobileMenu(!showMobileMenu)}
        >
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
        {/* Full-Screen Modal for Mobile Navbar */}
        {showMobileMenu && (
          <div className="fixed inset-0 bg-white z-20 flex flex-col p-6 space-y-6 overflow-y-auto">
            <div className="flex justify-between items-center">
              <img
                src={Logo}
                alt="MTM Nexus Logo"
                className="h-12 cursor-pointer"
                onClick={() => {
                  setShowMobileMenu(false);
                  navigate("/");
                }}
              />
              <div className="flex justify-center gap-x-4 items-center">
                {/* User Icon for Desktop */}
                {isLoggedIn && (
                  <div className="">
                    <UserIcon rightMargin={"-right-12"} />
                  </div>
                )}
                <button
                  className="text-gray-900 bg-slate-100 px-2 py-1 rounded-[50%] hover:bg-slate-200"
                  onClick={() => setShowMobileMenu(false)}
                >
                  <FontAwesomeIcon icon={faClose} size="lg" />
                </button>
              </div>
            </div>
            <nav className="flex flex-col space-y-4">
              <div>
                <button
                  className="flex items-center justify-between text-gray-900 w-full"
                  onClick={() => setShowProductSubmenu(!showProductSubmenu)}
                >
                  Product
                  <FontAwesomeIcon
                    icon={showProductSubmenu ? faCaretUp : faCaretDown}
                  />
                </button>
                {showProductSubmenu && (
                  <ul className="space-y-2">
                    {menuItems.map((item, index) => (
                      <li
                        key={index}
                        className={`py-2 pl-4 cursor-pointer pr-4 flex items-center rounded-md ${
                          item.status === "available"
                            ? "bg-gray-100 hover:bg-blue-100 text-gray-900 font-medium"
                            : "bg-gray-50 hover:bg-blue-50 text-gray-400"
                        }`}
                        onClick={() => {
                          if (item.status === "available") {
                            if (!isLoggedIn) {
                              navigate("/signin");
                            } else if (isPlanExpired === false && isLoggedIn) {
                              navigate("/analysisReport");
                            } else if (
                              isPlanExpired === null ||
                              isPlanExpired === true
                            ) {
                              navigate("/pricing");
                            }
                          }
                        }}
                      >
                        <span
                          className={`w-2.5 h-2.5 rounded-full flex-shrink-0 mr-2 ${
                            item.status === "available"
                              ? "bg-blue-600"
                              : "bg-gray-400"
                          }`}
                        ></span>
                        <span>{item.label}</span>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <a
                href="#about"
                onClick={() => {
                  setShowMobileMenu(false);
                }}
                className="text-gray-900 hover:text-blue-600"
              >
                About Us
              </a>
              <a
                href="#contact"
                onClick={() => {
                  setShowMobileMenu(false);
                }}
                className="text-gray-900 hover:text-blue-600"
              >
                Contact Us
              </a>
              <a
                onClick={() => navigate("/pricing")}
                className="text-gray-900 hover:text-blue-600 cursor-pointer"
              >
                Pricing
              </a>
            </nav>

            <div className="flex flex-col space-y-4 mt-6">
              {/* Social Media Icons */}
              <div className="flex space-x-4">
                <a
                  href="https://www.linkedin.com/company/mtm-nexus/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-700 hover:text-blue-600"
                >
                  <FontAwesomeIcon icon={faLinkedin} size="lg" />
                </a>
                {/* <a
                  href="https://www.instagram.com/mtmnexus/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-pink-500 hover:text-pink-400"
                >
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a> */}
              </div>

              {/* Analysis History & Sign In/Out */}
              {isLoggedIn && (
                <button
                  className="border border-blue-500 text-blue-500 px-4 py-1 rounded-lg hover:bg-blue-600 hover:text-white transition-all duration-200"
                  onClick={() => navigate("/analysisHistory")}
                >
                  <FontAwesomeIcon icon={faClock} />
                  <span className="ml-2">Analysis History</span>
                </button>
              )}
              <button
                onClick={
                  isLoggedIn
                    ? () => setIsLoggedIn(false)
                    : () => {
                        localStorage.setItem("SignFrom", "userIcon");
                        navigate("/signin");
                      }
                }
                className={`flex items-center px-4 py-2 rounded-xl ${
                  isLoggedIn
                    ? "bg-red-600 hover:bg-red-700 text-white"
                    : "bg-blue-500 hover:bg-blue-600 text-white"
                }`}
              >
                <FontAwesomeIcon
                  icon={isLoggedIn ? faSignOutAlt : faUser}
                  className="h-5 w-5"
                />
                <span className="ml-2">
                  {isLoggedIn ? "Sign Out" : "Sign In/Sign Up"}
                </span>
              </button>
            </div>
          </div>
        )}
        {/* Desktop Navbar */}
        <nav className="hidden md:flex flex-grow items-center justify-center space-x-6 ml-10">
          <div
            className="relative group"
            onMouseEnter={() => setShowDesktopProductSubmenu(true)}
            onMouseLeave={() => setShowDesktopProductSubmenu(false)}
          >
            <button className="flex items-center text-gray-900 hover:text-blue-600">
              Product
              <FontAwesomeIcon
                icon={showDesktopProductSubmenu ? faCaretUp : faCaretDown}
                className="ml-2"
              />
            </button>
            {showDesktopProductSubmenu && (
              <div className="absolute mt-2 top-4 bg-white shadow-lg border rounded-md z-30 p-4 w-[620px]">
                <ul className="space-y-2">
                  {menuItems.map((item, index) => (
                    <li
                      key={index}
                      className={`py-2 pl-4 pr-4 flex cursor-pointer items-center rounded-md ${
                        item.status === "available"
                          ? "bg-gray-100 hover:bg-blue-100 text-gray-900 font-medium"
                          : "bg-gray-50 hover:bg-blue-50 text-gray-400"
                      }`}
                      onClick={() => {
                        if (item.status === "available") {
                          if (!isLoggedIn) {
                            navigate("/signin");
                          } else if (isPlanExpired === false && isLoggedIn) {
                            navigate("/analysisReport");
                          } else if (
                            isPlanExpired === null ||
                            isPlanExpired === true
                          ) {
                            navigate("/pricing");
                          }
                        }
                      }}
                    >
                      <span
                        className={`w-2.5 h-2.5 rounded-full flex-shrink-0 mr-2 ${
                          item.status === "available"
                            ? "bg-blue-600"
                            : "bg-gray-400"
                        }`}
                      ></span>
                      <span>{item.label}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <a href="#about" className="text-gray-900 hover:text-blue-600">
            About Us
          </a>
          <a href="#contact" className="text-gray-900 hover:text-blue-600">
            Contact Us
          </a>
          <a
            onClick={() => navigate("/pricing")}
            className="text-gray-900 hover:text-blue-600 cursor-pointer"
          >
            Pricing
          </a>
          <div
            className="flex flex-start gap-x-4"
            style={{ marginLeft: "150px" }}
          >
            <a
              href="https://www.linkedin.com/company/mtm-nexus/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-700 hover:text-blue-600"
            >
              <FontAwesomeIcon icon={faLinkedin} size="lg" />
            </a>
            {/* <a
              href="https://www.instagram.com/mtmnexus/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-pink-500 hover:text-pink-400"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </a> */}
          </div>
        </nav>

        {/* Social Media and User Actions */}
        <div className="hidden md:flex items-center space-x-4 ml-auto">
          {isLoggedIn && (
            <button
              className="border border-blue-500 text-blue-500 px-4 py-1 rounded-lg hover:bg-blue-600 hover:text-white transition-all duration-200"
              onClick={() => navigate("/analysisHistory")}
            >
              <FontAwesomeIcon icon={faClock} />
              <span className="ml-2">Analysis History</span>
            </button>
          )}
          <button
            onClick={
              isLoggedIn
                ? () => setIsLoggedIn(false)
                : () => {
                    localStorage.setItem("SignFrom", "userIcon");
                    navigate("/signin");
                  }
            }
            className={`flex items-center px-4 py-2 rounded-xl ${
              isLoggedIn
                ? "bg-red-600 hover:bg-red-700 text-white"
                : "bg-blue-500 hover:bg-blue-600 text-white"
            }`}
          >
            <FontAwesomeIcon
              icon={isLoggedIn ? faSignOutAlt : faUser}
              className="h-5 w-5"
            />
            <span className="ml-2">
              {isLoggedIn ? "Sign Out" : "Sign In/Sign Up"}
            </span>
          </button>
          {/* User Icon for Desktop */}
          {isLoggedIn && (
            <div className="hidden md:block">
              <UserIcon rightMargin={"-right-12"} />
            </div>
          )}
        </div>
      </header>

      {/* Main Content */}
      <div className="flex flex-col h-screen">
        <div
          className="flex flex-1 flex-col md:flex-row items-center justify-between p-6 md:p-12 relative bg-center md:bg-right bg-cover md:bg-contain mt-[2px]"
          style={{
            backgroundImage: `url(${HomeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundBlendMode: "color",
          }}
        >
          {/* Text Content */}
          <div className="text-center md:text-left z-4 max-w-md w-full">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900 leading-tight md:leading-snug tracking-wide">
              {fifthTextData?.HOME_TITLE_BLACK} <br />
              <span className="text-[#007AFF]">
                {fifthTextData?.HOME_TITLE_BLUE}
              </span>
            </h1>
            <p className="text-lg mb-8 text-white md:text-[#0000008A] leading-relaxed tracking-normal">
              {fifthTextData?.HOME_SUBTITLE}
            </p>

            <button
              className="bg-[#007AFF] text-white px-6 py-3 rounded-3xl text-lg hover:bg-blue-600"
              style={{
                boxShadow: "0 4px 15px 0 rgba(0, 122, 255, 0.6)",
                transition: "box-shadow 0.3s ease",
              }}
              onClick={() => handleClick("startIcon")}
            >
              Start MTM Analysis
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <div id="about" className="flex flex-col h-[130vh] md:h-screen">
        <AboutUs />
      </div>

      {/* Contact Us Section */}
      <div id="contact" className="flex flex-col">
        <ContactUs />
      </div>

      {/* Footer */}
      <footer className="bg-[#007AFF] text-white text-center py-4 mt-2">
        <p>&copy; {new Date().getFullYear()} MTM Nexus. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default Home;
