import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import Logo from "../assets/mtmNexusLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOutAlt,
  faSpinner,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import {
  createCheckoutSessionAsync,
  fetchProfileAsync,
  resetAuthComponent,
} from "../redux/authSlice"; // Assuming you have this action in your Redux slice
import { toast } from "react-toastify";
import UserIcon from "./UserIcon";

// Load Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Pricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const {
    profileData,
    createCheckoutSessionData,
    loadingCreateCheckoutSession,
    errorCreateCheckoutSession,
  } = useSelector((state) => state.auth);
  const [loadingStripe, setLoadingStripe] = useState(false);

  const isPlanExpired =
    profileData?.subscription &&
    new Date() > new Date(profileData?.subscription?.endDate);

  useEffect(() => {
    // Check if the user is logged in on component mount
    setIsLoggedIn(!!localStorage.getItem("token"));
    dispatch(resetAuthComponent());
    dispatch(fetchProfileAsync({ userId: localStorage.getItem("userID") }));
  }, []);

  useEffect(() => {
    const redirectToStripe = async () => {
      if (createCheckoutSessionData?.sessionId) {
        const stripe = await stripePromise;
        setLoadingStripe(false);

        // Redirect to Stripe checkout
        const { error } = await stripe.redirectToCheckout({
          sessionId: createCheckoutSessionData.sessionId,
        });

        if (error) {
          toast.error(error);
          console.error("Stripe Checkout Error:", error);
        }
      } else if (errorCreateCheckoutSession) {
        setLoadingStripe(false);
        toast.error(errorCreateCheckoutSession);
      }
    };

    redirectToStripe();
  }, [createCheckoutSessionData, errorCreateCheckoutSession]);

  const handleChoosePlan = async () => {
    const userId = localStorage.getItem("userID");

    if (!isLoggedIn) {
      localStorage.setItem("SignFrom", "pricing");
      navigate("/signin");
    } else {
      dispatch(
        createCheckoutSessionAsync({
          userId,
          plan: "MTM Nexus Unified Subscription Plan",
          price: 2000,
        })
      );
      setLoadingStripe(true);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    navigate("/");
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header */}
      <header
        className="flex justify-between items-center px-6 py-4 bg-white shadow-md flex-wrap"
        style={{ boxShadow: "0 2px 2px #00000040" }}
      >
        <div className="flex items-center space-x-4">
          <img
            src={Logo}
            alt="MTM Nexus Logo"
            className="h-16 md:w-32 cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        <div className="flex items-center space-x-4 order-last md:order-none mt-4 md:mt-0 ml-5 md:ml-0">
          {/* Social Media Icons */}
          <a
            href="https://www.linkedin.com/company/mtm-nexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-700 hover:text-blue-600"
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          {/* <a
            href="https://www.instagram.com/mtmnexus/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-pink-500 hover:text-pink-400"
          >
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a> */}
        </div>

        <div className="flex items-center space-x-4 mt-4 md:mt-0">
          <button
            onClick={() => (isLoggedIn ? handleLogout() : navigate("/signin"))}
            className={`flex items-center px-4 py-2 rounded-xl ${
              isLoggedIn
                ? "bg-red-600 hover:bg-red-700 text-white"
                : "bg-[#007AFF] hover:bg-blue-600 text-white"
            }`}
          >
            <FontAwesomeIcon
              icon={isLoggedIn ? faSignOutAlt : faUser}
              className="h-5 w-5"
            />
            <span className="hidden md:inline ml-2">
              {isLoggedIn ? "Sign Out" : "Sign In/Sign Up"}
            </span>
          </button>
          {/* User Icon for Desktop */}
          {isLoggedIn && (
            <div>
              <UserIcon rightMargin={"-right-16"} />
            </div>
          )}
        </div>
      </header>

      {/* Title and Subtitle */}
      <div className="text-center mt-6 sm:mt-10 px-4">
        <h1
          className="text-2xl sm:text-3xl font-extrabold text-[#007AFF] mb-8 my-8"
          style={{ letterSpacing: "0.1em" }}
        >
          Purchase the MTM Nexus Unified Subscription Plan
        </h1>
      </div>

      {/* Pricing Box */}
      <div className="flex flex-col items-center justify-center p-6 md:p-12 md:py-6 bg-white rounded-lg shadow-lg w-[90%] max-w-3xl mx-auto mb-8">
        <h1 className="text-xl sm:text-3xl font-bold text-gray-900 mb-4 text-center leading-tight tracking-wide">
          MTM Nexus Unified Subscription Plan
        </h1>
        <p className="text-lg sm:text-2xl font-bold text-blue-600 mb-2 text-center">
          $2,000/month per client
        </p>
        <h3 className="text-xl bold mb-1">Plan Includes</h3>
        <ul className="list-disc">
          <li className="text-[14px] text-gray-700 mb-4 text-left leading-relaxed">
            <b> AI-Powered Medication Therapy Management (MTM) </b>leverages
            advanced artificial intelligence to optimize patient medication
            regimens, enhance adherence, and improve therapeutic outcomes. By
            analyzing vast amounts of patient data, AI identifies potential
            medication interactions, gaps in care, and cost-saving
            opportunities. It empowers healthcare providers with actionable
            insights, streamlines prior authorizations, and fosters
            collaboration between pharmacists, physicians, and insurers. This
            innovative approach ensures personalized, evidence-based
            interventions, improving patient safety and reducing healthcare
            costs.
          </li>
          <li className="text-[14px] text-gray-700 mb-4 text-left leading-relaxed">
            <b>Licensed and Registered Pharmacist Review Oversight </b>ensures
            the accuracy, compliance, and quality of medication-related
            processes through supervision by licensed pharmacists. By combining
            pharmaceutical knowledge with regulatory expertise, licensed
            pharmacists provide vital oversight to safeguard patient health,
            optimize therapeutic outcomes, and maintain compliance with
            healthcare standards.
          </li>
        </ul>
        {isPlanExpired === null || isPlanExpired === true ? (
          <button
            className={`mt-4 px-6 py-3 font-semibold rounded-lg shadow-md transition-colors duration-200 ${
              loadingCreateCheckoutSession || loadingStripe
                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                : "bg-blue-600 text-white hover:bg-blue-700"
            }`}
            onClick={handleChoosePlan}
            disabled={loadingCreateCheckoutSession || loadingStripe}
          >
            {loadingCreateCheckoutSession || loadingStripe ? (
              <span className="flex items-center gap-2">
                <FontAwesomeIcon icon={faSpinner} spin />
                Processing...
              </span>
            ) : (
              "Choose Plan"
            )}
          </button>
        ) : (
          <p className="text-lg text-green-600 font-semibold text-center">
            Valid until:{" "}
            {new Date(profileData?.subscription?.endDate).toLocaleDateString()}
          </p>
        )}
      </div>
    </div>
  );
};

export default Pricing;
