import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { fetchProfileAsync } from "../redux/authSlice";

const PaymentCancel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(10); // Initialize the countdown timer

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1); // Decrease the countdown by 1 every second
    }, 1000);

    // Redirect when countdown reaches 0
    if (countdown === 0) {
      clearInterval(timer);
      navigate("/");
    }

    return () => clearInterval(timer); // Clear timer on component unmount
  }, [countdown, navigate]);

  useEffect(() => {
    if (localStorage.getItem("userID")) {
      dispatch(fetchProfileAsync({ userId: localStorage.getItem("userID") }));
    }
  }, [localStorage.getItem("userID")]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-red-50 px-4">
      <div className="bg-white shadow-lg rounded-lg p-6 md:p-12 w-full max-w-3xl text-center">
        {/* Cancel Icon */}
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-red-600 text-6xl mb-6"
        />

        <h1 className="text-3xl font-bold text-red-700 mb-4">
          Payment Cancelled
        </h1>
        <p className="text-md sm:text-lg text-gray-700 mb-6">
          Your payment was not completed. If you want to try again, please go
          back to the subscription page.
        </p>

        {/* Countdown Message */}
        <p className="text-blue-500 mb-6 underline">
          Redirecting in {countdown} seconds...
        </p>

        <button
          className="px-6 py-3 bg-red-600 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 transition-colors duration-200"
          onClick={() => navigate("/")}
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default PaymentCancel;
