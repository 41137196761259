// authSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

//const modifiedUrl = "";
// const modifiedUrl = `https://mtmnexus.com`;
// const modifiedUrl = "https://e937-125-63-73-50.ngrok-free.app";
const modifiedUrl = "https://mtmnexus.com";

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (credentials) => {
    try {
      const response = await fetch(`${modifiedUrl}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(credentials),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// Signup
export const signupAsync = createAsyncThunk("auth/signup", async (userData) => {
  console.log("signup data sending", userData);
  try {
    const response = await fetch(`${modifiedUrl}/api/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error);
    }

    return data;
  } catch (error) {
    throw error.message || "An error occurred";
  }
});

// Forget Password
export const forgetPasswordAsync = createAsyncThunk(
  "auth/forgetPassword",
  async (receiver_email) => {
    console.log("sendMailPayload", receiver_email, modifiedUrl);
    try {
      const response = await fetch(`${modifiedUrl}/api/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(receiver_email),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);
// export const setResetEmail = (email) => ({
//   type: "auth/setResetEmail",
//   payload: email,
// });
// Reset Password
export const resetPasswordAsync = createAsyncThunk(
  "auth/resetPassword",
  async (newResetData) => {
    try {
      const response = await fetch(`${modifiedUrl}/api/auth/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newResetData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

export const logoutAsync = createAsyncThunk("auth/logout", async () => {
  try {
    const token = await localStorage.getItem("token");
    console.log("logout data sending", { token });
    const response = await fetch(`${modifiedUrl}/api/auth/logout`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({ fcmToken: fcmToken }),
    });

    if (!response.ok) {
      const data = await response.json(); // Capture error message from API
      throw new Error(data.error || "Logout failed");
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message || "An error occurred");
  }
});

export const submitAnalysisReportAsync = createAsyncThunk(
  "auth/submitAnalysisReport",
  async (reportData) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(`${modifiedUrl}/api/patients/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reportData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }
      // console.log("data", data, data.pdfLink);
      localStorage.setItem("report", data?.pdfLink);
      return data.pdfLink;
    } catch (error) {
      console.log("//////", error);
      toast.error("An error occurred");
      throw error.message || "An error occurred";
    }
  }
);

// Suggestion API
export const suggestionAsync = createAsyncThunk(
  "auth/suggestion",
  async (query) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await fetch(
        `${modifiedUrl}/api/patients/search-medications?term=${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          //   body: JSON.stringify({ query }),
        }
      );
      const data = await response.json();
      if (!response.ok)
        throw new Error(data.error || "Failed to fetch suggestions");
      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// suggestionDiagnosisCodeAsync API
export const suggestionDiagnosisCodeAsync = createAsyncThunk(
  "auth/suggestionDiagnosisCode",
  async (query) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await fetch(
        `${modifiedUrl}/api/patients/fetch-medication-details?name=${query}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          //   body: JSON.stringify({ query }),
        }
      );
      const data = await response.json();
      if (!response.ok)
        throw new Error(data.error || "Failed to fetch suggestions");
      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

//AnalysisHistory API
export const fetchAnalysisHistoryAsync = createAsyncThunk(
  "auth/fetchAnalysisHistory",
  async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${modifiedUrl}/api/patients/getAllpatientsReports`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(
          responseData.error || "Failed to fetch analysis history"
        );
      }

      const formattedData = responseData.data.map((item) => ({
        id: item._id,
        name: item.name,
        age: item.age,
        date: new Date(item.createdAt).toLocaleDateString(),
        pdfLink: item?.pdfLink,
      }));

      return formattedData;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// Create checkout session
export const createCheckoutSessionAsync = createAsyncThunk(
  "stripe/create-checkout-session",
  async (userData) => {
    console.log("signup data sending", userData);
    try {
      const token = await localStorage.getItem("token");
      const response = await fetch(
        `${modifiedUrl}/api/stripe/create-checkout-session`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error);
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// Profile API
export const fetchProfileAsync = createAsyncThunk(
  "auth/fetchProfile",
  async (payload) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await fetch(`${modifiedUrl}/api/auth/profile`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch profile");
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// Cancel Subscription API
export const cancelSubscriptionAsync = createAsyncThunk(
  "stripe/cancelSubscription",
  async (payload) => {
    try {
      const token = await localStorage.getItem("token");
      const response = await fetch(
        `${modifiedUrl}/api/stripe/cancel-subscription`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to cancel subscription");
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// Fifth Text API
export const fetchFifthTextAsync = createAsyncThunk(
  "text/fifthText",
  async () => {
    try {
      const response = await fetch(`${modifiedUrl}/api/text/content-text`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch text");
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// Resume Subscription API
export const resumeSubscriptionAsync = createAsyncThunk(
  "stripe/resumeSubscription",
  async (payload) => {
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(
        `${modifiedUrl}/api/stripe/resume-subscription`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to resume subscription");
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

// Contact Us API
export const contactUsAsync = createAsyncThunk(
  "auth/contactUs",
  async (payload) => {
    try {
      const response = await fetch(`${modifiedUrl}/api/auth/contactUs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to send contact request");
      }

      return data;
    } catch (error) {
      throw error.message || "An error occurred";
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    emailForReset: null,
    analysisReport: null,
    analysisHistory: [],
    errorAnalysisHistory: null,
    statusAnalysisHistory: "idle",
    loadingAnalysisHistory: false,
    dataReset: null,
    errorReset: null,
    statusReset: "idle",
    loadingReset: false,

    dataForget: null,
    errorForget: null,
    statusForget: "idle",
    loadingForget: false, // Add loading state

    userLogin: null,
    errorLogin: null,
    statusLogin: "idle",
    loadingLogin: false,

    userSignUp: null,
    errorSignUp: null,
    statusSignUp: "idle",
    loadingSignUp: false,

    logout: null,
    errorLogout: null,
    loadingLogout: false,
    errorSuggestion: null,
    loadingSuggestion: false,

    createCheckoutSessionData: null,
    errorCreateCheckoutSession: null,
    loadingCreateCheckoutSession: false,

    loadingProfile: false,
    profileData: null,
    errorProfile: null,

    loadingCancelSubscription: false,
    cancelSubscriptionData: null,
    errorCancelSubscription: null,

    loadingFifthText: false,
    fifthTextData: null,
    errorFifthText: null,

    loadingResumeSubscription: false,
    resumeSubscriptionData: null,
    errorResumeSubscription: null,

    loadingContactUs: false,
    contactUsData: null,
    errorContactUs: null,
  },
  reducers: {
    resetAuthComponent(state) {
      state.emailForReset = null;
      state.userLogin = null;
      state.errorLogin = null;
      state.userSignUp = null;
      state.errorSignUp = null;
      state.dataForget = null;
      state.errorForget = null;
      state.dataReset = null;
      state.errorReset = null;
      state.suggestionData = null;
      state.errorSuggestion = null;
      state.loadingDiagnosisCode = false;
      state.DiagnosisCodeData = null;
      state.errorDiagnosisCode = null;
    },
    emailForReset: (state, action) => {
      state.emailForReset = action.payload;
    },
    resetSuggestions(state) {
      state.suggestionData = null;
      state.errorSuggestion = null;
      state.DiagnosisCodeData = null;
      state.errorDiagnosisCode = null;
    },
    resetCancelSubscriptionData(state) {
      state.loadingCancelSubscription = false;
      state.cancelSubscriptionData = null;
      state.errorCancelSubscription = null;
    },
    resetContactUs(state) {
      state.loadingContactUs = false;
      state.contactUsData = null;
      state.errorContactUs = null;
    },
    resetResumeSubscription(state) {
      state.loadingResumeSubscription = false;
      state.resumeSubscriptionData = null;
      state.errorResumeSubscription = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalysisHistoryAsync.pending, (state) => {
        state.statusAnalysisHistory = "loading";
        state.loadingAnalysisHistory = true;
      })
      .addCase(fetchAnalysisHistoryAsync.fulfilled, (state, action) => {
        state.statusAnalysisHistory = "succeeded";
        state.analysisHistory = action.payload;
        state.errorAnalysisHistory = null;
        state.loadingAnalysisHistory = false;
      })
      .addCase(fetchAnalysisHistoryAsync.rejected, (state, action) => {
        state.statusAnalysisHistory = "failed";
        state.errorAnalysisHistory =
          action.error.message || "An error occurred";
        state.loadingAnalysisHistory = false;
      })

      .addCase(loginAsync.pending, (state) => {
        state.statusLogin = "loading";
        state.loadingLogin = true;
      })
      .addCase(submitAnalysisReportAsync.pending, (state) => {
        state.statusAnalysisReport = "loading";
        state.loadingAnalysisReport = true;
      })
      .addCase(submitAnalysisReportAsync.fulfilled, (state, action) => {
        state.statusAnalysisReport = "succeeded";
        state.analysisReport = action.payload;
        state.errorAnalysisReport = null;
        state.loadingAnalysisReport = false;
      })
      .addCase(submitAnalysisReportAsync.rejected, (state, action) => {
        state.statusAnalysisReport = "failed";
        state.analysisReport = null;
        state.errorAnalysisReport = action.error.message || "An error occurred";
        state.loadingAnalysisReport = false;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        console.log("loginAsync success", action);
        state.statusLogin = "succeeded";
        state.userLogin = action.payload;
        state.errorLogin = null;
        state.loadingLogin = false;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        console.log("loginAsync fail", action);
        state.statusLogin = "failed";
        state.userLogin = null;
        state.errorLogin = action.error.message || "An error occurred";
        state.loadingLogin = false;
      })
      .addCase(signupAsync.pending, (state) => {
        state.statusSignUp = "loading";
        state.loadingSignUp = true;
      })
      .addCase(signupAsync.fulfilled, (state, action) => {
        console.log("signup", action?.payload);
        state.statusSignUp = "succeeded";
        state.userSignUp = action.payload;
        state.errorSignUp = null;
        state.loadingSignUp = false;
      })
      .addCase(signupAsync.rejected, (state, action) => {
        console.log("signup error", action);
        state.statusSignUp = "failed";
        state.userSignUp = null;
        state.errorSignUp = action.error.message || "An error occurred";
        state.loadingSignUp = false;
      })
      .addCase(forgetPasswordAsync.pending, (state) => {
        state.statusForget = "loading";
        state.loadingForget = true;
      })
      .addCase(forgetPasswordAsync.fulfilled, (state, action) => {
        console.log("sendMail", action?.payload);
        state.statusForget = "succeeded";
        state.dataForget = action.payload;
        state.errorForget = null;
        state.loadingForget = false;
        state.emailForReset = action.meta.arg;
      })
      .addCase(forgetPasswordAsync.rejected, (state, action) => {
        console.log("sendMail Error ", action);
        state.statusForget = "failed";
        state.dataForget = null;
        // state.errorForget = action.error.message || "Error sending OTP";
        state.errorForget = "Error sending OTP" || action.error.message;
        state.loadingForget = false;
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.statusReset = "loading";
        state.loadingReset = true;
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.statusReset = "succeeded";
        state.dataReset = action.payload;
        state.errorReset = null;
        state.loadingReset = false;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.statusReset = "failed";
        state.dataReset = null;
        // state.errorReset = action.error.message || "Invalid or expired OTP";
        state.errorReset = "Invalid or expired OTP" || action.error.message;
        state.loadingReset = false;
      })
      .addCase(logoutAsync.pending, (state) => {
        state.loadingLogout = true;
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        console.log("logoutAsync success", action);
        // Perform any necessary state updates upon successful logout
        state.logout = action.payload;
        state.errorLogout = null;
        state.loadingLogout = false;
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        console.log("logoutAsync error", action);
        state.logout = null;
        state.errorLogout = action.error.message || "An error occurred";
        state.loadingLogout = false;
      })
      .addCase(suggestionAsync.pending, (state) => {
        state.loadingSuggestion = true;
      })
      .addCase(suggestionAsync.fulfilled, (state, action) => {
        state.loadingSuggestion = false;
        state.suggestionData = action?.payload?.data?.filter(
          (item) => item.name
        );
        state.errorSuggestion = null;
      })
      .addCase(suggestionAsync.rejected, (state, action) => {
        state.loadingSuggestion = false;
        state.suggestionData = null;
        state.errorSuggestion = action.error.message || "An error occurred";
      })
      .addCase(suggestionDiagnosisCodeAsync.pending, (state) => {
        state.loadingDiagnosisCode = true;
      })
      .addCase(suggestionDiagnosisCodeAsync.fulfilled, (state, action) => {
        state.loadingDiagnosisCode = false;
        state.DiagnosisCodeData = action?.payload?.data;
        state.errorDiagnosisCode = null;
      })
      .addCase(suggestionDiagnosisCodeAsync.rejected, (state, action) => {
        state.loadingDiagnosisCode = false;
        state.DiagnosisCodeData = null;
        state.errorDiagnosisCode = action.error.message || "An error occurred";
      })
      .addCase(createCheckoutSessionAsync.pending, (state) => {
        state.loadingCreateCheckoutSession = true;
      })
      .addCase(createCheckoutSessionAsync.fulfilled, (state, action) => {
        console.log("createCheckoutSessionAsync success", action?.payload);
        state.createCheckoutSessionData = action.payload;
        state.errorCreateCheckoutSession = null;
        state.loadingCreateCheckoutSession = false;
      })
      .addCase(createCheckoutSessionAsync.rejected, (state, action) => {
        console.log("createCheckoutSessionAsync error", action);
        state.createCheckoutSessionData = null;
        state.errorCreateCheckoutSession =
          action.error.message || "An error occurred";
        state.loadingCreateCheckoutSession = false;
      })
      .addCase(fetchProfileAsync.pending, (state) => {
        state.loadingProfile = true;
      })
      .addCase(fetchProfileAsync.fulfilled, (state, action) => {
        console.log("fetchProfileAsync", action?.payload);
        state.loadingProfile = false;
        state.profileData = action.payload;
        state.errorProfile = null;
      })
      .addCase(fetchProfileAsync.rejected, (state, action) => {
        state.loadingProfile = false;
        state.profileData = null;
        state.errorProfile = action.error.message || "An error occurred";
      })

      // Cancel Subscription API
      .addCase(cancelSubscriptionAsync.pending, (state) => {
        state.loadingCancelSubscription = true;
      })
      .addCase(cancelSubscriptionAsync.fulfilled, (state, action) => {
        console.log("cancelSubscriptionAsync", action?.payload);
        state.loadingCancelSubscription = false;
        state.cancelSubscriptionData = action.payload;
        state.errorCancelSubscription = null;
      })
      .addCase(cancelSubscriptionAsync.rejected, (state, action) => {
        state.loadingCancelSubscription = false;
        state.cancelSubscriptionData = null;
        state.errorCancelSubscription =
          action.error.message || "An error occurred";
      })
      // Fifth Text API
      .addCase(fetchFifthTextAsync.pending, (state) => {
        state.loadingFifthText = true;
      })
      .addCase(fetchFifthTextAsync.fulfilled, (state, action) => {
        console.log("fetch text", action?.payload);
        state.loadingFifthText = false;
        state.fifthTextData = action?.payload?.message;
        state.errorFifthText = null;
      })
      .addCase(fetchFifthTextAsync.rejected, (state, action) => {
        state.loadingFifthText = false;
        state.fifthTextData = null;
        state.errorFifthText = action.error.message || "An error occurred";
      })

      // Resume Subscription API
      .addCase(resumeSubscriptionAsync.pending, (state) => {
        state.loadingResumeSubscription = true;
      })
      .addCase(resumeSubscriptionAsync.fulfilled, (state, action) => {
        state.loadingResumeSubscription = false;
        state.resumeSubscriptionData = action.payload;
        state.errorResumeSubscription = null;
      })
      .addCase(resumeSubscriptionAsync.rejected, (state, action) => {
        state.loadingResumeSubscription = false;
        state.resumeSubscriptionData = null;
        state.errorResumeSubscription =
          action.error.message || "An error occurred";
      })

      // Contact Us API
      .addCase(contactUsAsync.pending, (state) => {
        state.loadingContactUs = true;
      })
      .addCase(contactUsAsync.fulfilled, (state, action) => {
        state.loadingContactUs = false;
        state.contactUsData = action.payload;
        state.errorContactUs = null;
      })
      .addCase(contactUsAsync.rejected, (state, action) => {
        state.loadingContactUs = false;
        state.contactUsData = null;
        state.errorContactUs = action.error.message || "An error occurred";
      });
  },
});
export const { emailForReset } = authSlice.actions;
export const {
  resetAuthComponent,
  resetSuggestions,
  resetCancelSubscriptionData,
  resetContactUs,
  resetResumeSubscription,
} = authSlice.actions;
export default authSlice.reducer;
