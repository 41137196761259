import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { fetchProfileAsync } from "../redux/authSlice";
import { useDispatch } from "react-redux";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(8); // Initialize the countdown timer

  const currentDate = new Date();
  const validUntil = new Date(currentDate.setMonth(currentDate.getMonth() + 1))
    .toISOString()
    .split("T")[0]; // Format: YYYY-MM-DD

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1); // Decrease the countdown by 1 every second
    }, 1000);

    // Redirect when countdown reaches 0
    if (countdown === 0) {
      clearInterval(timer);
      navigate("/analysisreport");
    }

    return () => clearInterval(timer); // Clear timer on component unmount
  }, [countdown, navigate]);
  useEffect(() => {
    if (localStorage.getItem("userID")) {
      dispatch(fetchProfileAsync({ userId: localStorage.getItem("userID") }));
    }
  }, [localStorage.getItem("userID")]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-green-50 px-4">
      <div className="bg-white shadow-lg rounded-lg p-6 md:p-12 w-full max-w-3xl text-center">
        {/* Success Icon */}
        <FontAwesomeIcon
          icon={faCheckCircle}
          className="text-green-600 text-6xl mb-6"
        />

        <h1 className="text-3xl font-bold text-green-700 mb-4">
          Payment Successful!
        </h1>
        {/* <h1 className="text-xl sm:text-3xl font-bold text-gray-900 mb-4 text-center leading-tight tracking-wide">
          MTM Nexus Unified Subscription Plan
        </h1>
        <p className="text-lg sm:text-2xl font-bold text-blue-600 mb-4 text-center">
          $2,000/month per client
        </p> */}
        <p className="text-md sm:text-lg text-gray-700 mb-6">
          Your subscription is valid until: <strong>{validUntil}</strong>
        </p>

        {/* Countdown Message */}
        <p className="text-blue-500 mb-6 underline">
          Redirecting in {countdown} seconds...
        </p>

        <button
          className="px-6 py-3 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition-colors duration-200"
          onClick={() => navigate("/analysisreport")}
        >
          Go to Analysis Report
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
