import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faKey,
  faCalendarAlt,
  faWallet,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSubscriptionAsync,
  fetchProfileAsync,
  resetCancelSubscriptionData,
  resetResumeSubscription,
  resumeSubscriptionAsync,
} from "../redux/authSlice"; // Added resumeSubscriptionAsync
import { toast } from "react-toastify";

const UserIcon = ({ rightMargin }) => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isResume, setIsResume] = useState(false); // Tracks action type (Resume/Cancel)
  const modalRef = useRef(null);
  const buttonRef = useRef(null);
  const navigate = useNavigate();

  const {
    profileData,
    loadingCancelSubscription,
    cancelSubscriptionData,
    errorCancelSubscription,
    loadingResumeSubscription,
    resumeSubscriptionData,
    errorResumeSubscription,
  } = useSelector((state) => state.auth);

  const getBgColor = (char) => {
    const colors = [
      "#F44336",
      "#E91E63",
      "#9C27B0",
      "#673AB7",
      "#3F51B5",
      "#2196F3",
      "#03A9F4",
      "#00BCD4",
      "#009688",
      "#4CAF50",
      "#8BC34A",
      "#CDDC39",
      "#FFC107",
      "#FF9800",
      "#FF5722",
      "#795548",
      "#607D8B",
    ];
    const charCode = char?.toUpperCase().charCodeAt(0);
    return colors[charCode % colors.length];
  };

  const bgColor = getBgColor(profileData?.user?.email[0]);

  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const handleBuyPlan = () => {
    navigate("/pricing");
  };

  const handleCancelSubscription = () => {
    setIsResume(false);
    setShowConfirmModal(true);
  };

  const handleResumeSubscription = () => {
    setIsResume(true);
    setShowConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const confirmSubscriptionAction = () => {
    const userId = localStorage.getItem("userID");
    if (isResume) {
      dispatch(resumeSubscriptionAsync({ userId }));
    } else {
      dispatch(cancelSubscriptionAsync({ userId }));
    }
  };

  useEffect(() => {
    if (cancelSubscriptionData !== null) {
      toast.success("Subscription canceled successfully!");
      dispatch(resetCancelSubscriptionData());
      setShowConfirmModal(false);
      dispatch(fetchProfileAsync({ userId: localStorage.getItem("userID") }));
    } else if (errorCancelSubscription) {
      toast.error("Failed to cancel subscription. Please try again.");
      dispatch(resetCancelSubscriptionData());
      dispatch(fetchProfileAsync({ userId: localStorage.getItem("userID") }));
    }
  }, [cancelSubscriptionData, errorCancelSubscription, dispatch]);

  useEffect(() => {
    if (resumeSubscriptionData !== null) {
      toast.success("Subscription resumed successfully!");
      setShowConfirmModal(false);
      dispatch(resetResumeSubscription());
      dispatch(fetchProfileAsync({ userId: localStorage.getItem("userID") }));
    } else if (errorResumeSubscription) {
      toast.error("Failed to resume subscription. Please try again.");
      dispatch(resetResumeSubscription());
      setShowConfirmModal(false);
    }
  }, [resumeSubscriptionData, errorResumeSubscription, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowDetails(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isPlanExpired =
    profileData?.subscription &&
    new Date() > new Date(profileData?.subscription?.endDate);

  return (
    <div className="relative">
      {/* User Icon */}
      <button
        ref={buttonRef}
        className="flex items-center space-x-2 focus:outline-none cursor-pointer"
        onClick={toggleDetails}
      >
        <div
          className="w-10 h-10 rounded-full flex items-center justify-center text-xl text-white"
          style={{ backgroundColor: bgColor }}
        >
          {profileData?.user?.email[0].toUpperCase()}
        </div>
        <FontAwesomeIcon
          icon={showDetails ? faCaretUp : faCaretDown}
          className="text-gray-800"
        />
      </button>

      {/* User Details Modal */}
      {showDetails && (
        <div
          ref={modalRef}
          className={`absolute ${rightMargin} md:right-0 mt-2 w-80 bg-white shadow-lg rounded-lg z-50 p-4`}
          style={{ boxShadow: "0 4px 15px rgba(0, 122, 255, 0.2)" }}
        >
          <div className="flex flex-col items-center mt-4">
            <h3 className="mt-2 text-xl font-bold text-gray-800">
              {profileData?.user?.email.split("@")[0]}
            </h3>
            <p className="text-sm text-gray-600">{profileData?.user?.email}</p>
          </div>

          {/* Subscription Details */}
          {profileData?.subscription ? (
            <>
              <div className="flex flex-col mt-6 text-gray-800 space-y-4">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faKey}
                    size="lg"
                    className="text-[#007AFF] mr-3"
                  />
                  <div className="flex flex-row gap-x-2">
                    <p className="text-sm font-bold">Subscription Status:</p>
                    <p
                      className={
                        profileData?.subscription?.status === "Cancelled"
                          ? `text-sm text-red-600`
                          : `text-sm text-green-600`
                      }
                    >
                      {profileData?.subscription?.status}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faWallet}
                    size="lg"
                    className="text-[#007AFF] mr-3"
                  />
                  <div className="flex flex-row gap-x-2">
                    <p className="text-sm font-bold">Amount:</p>
                    <p className="text-sm text-gray-600">
                      ${profileData?.subscription?.amount} per month
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    size="lg"
                    className="text-[#007AFF] mr-3"
                  />
                  <div className="flex flex-row gap-x-2">
                    <p className="text-sm font-bold">Start Date:</p>
                    <p className="text-sm text-gray-600">
                      {new Date(
                        profileData?.subscription?.startDate
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    size="lg"
                    className="text-[#007AFF] mr-3"
                  />
                  <div className="flex flex-row gap-x-2">
                    <p className="text-sm font-bold">End Date:</p>
                    <p className="text-sm text-gray-600">
                      {new Date(
                        profileData?.subscription?.endDate
                      ).toLocaleDateString()}{" "}
                      {isPlanExpired ? (
                        <span className="text-red-600">(Expired)</span>
                      ) : (
                        <span className="text-green-600">(Valid Upto)</span>
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {/* Footer */}
              {profileData?.subscription?.amount !== 0 && (
                <div className="mt-6 text-sm text-center">
                  {profileData?.subscription?.status === "Cancelled" ? (
                    isPlanExpired ? (
                      <button
                        className="text-green-600 underline hover:text-green-700"
                        onClick={handleBuyPlan}
                      >
                        Activate / Buy Plan
                      </button>
                    ) : (
                      <button
                        className="text-green-600 underline hover:text-green-700"
                        onClick={handleResumeSubscription}
                      >
                        Resume Plan
                      </button>
                    )
                  ) : (
                    <button
                      className="text-red-600 underline hover:text-red-700"
                      onClick={handleCancelSubscription}
                    >
                      Cancel My Subscription
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {/* No Plan Active Section */}
              <div className="mt-6 text-gray-600 text-center">
                <p className="text-lg font-bold">No Plan Active</p>
              </div>
              {/* Footer */}
              <div className="mt-6 text-sm text-center">
                <button
                  className="text-green-600 underline hover:text-green-700"
                  onClick={handleBuyPlan}
                >
                  Activate / Buy Plan
                </button>
              </div>
            </>
          )}
        </div>
      )}

      {/* Confirmation Modal */}
      {showConfirmModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg p-6 shadow-lg text-center">
            <h3 className="text-lg font-bold text-gray-800 mb-4">
              Are you sure you want to{" "}
              {isResume
                ? "resume your subscription?"
                : "cancel your subscription?"}
            </h3>
            <div className="flex justify-center space-x-4">
              <button
                className={`${
                  isResume ? "bg-green-600" : "bg-red-600"
                } text-white px-4 py-2 rounded-lg hover:${
                  isResume ? "bg-green-700" : "bg-red-700"
                }`}
                onClick={confirmSubscriptionAction}
              >
                {loadingCancelSubscription || loadingResumeSubscription ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : isResume ? (
                  "Yes, Resume"
                ) : (
                  "Yes, Cancel"
                )}
              </button>
              <button
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400"
                onClick={closeConfirmModal}
              >
                No, Keep It
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserIcon;
